/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

:root {
    --colorBase: #004A94;
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(45px);
              transform: translateX(45px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(45px);
              transform: translateX(45px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
      opacity: 1;
    }
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
